<template>
  <div>
    <v-card>
      <v-card-title> {{ form.id > 0 ? "修改" : "新增" }}域名分配 </v-card-title>
      <v-card-text>
        <v-text-field label="域名" v-model="form.domain"></v-text-field>
        <v-text-field
          label="IPV6地址"
          v-model="form.dst_ip_addr_v6"
        ></v-text-field>
        <v-text-field
          label="客户端地址"
          v-model="form.src_ip_addr_v4"
        ></v-text-field>
        <v-text-field
          label="有效期"
          v-model="form.ttl"
          type="number"
          suffix="天"
        ></v-text-field>
        <v-btn
          :loading="loading"
          depressed
          color="primary"
          block
          @click="submit"
        >
          提交
        </v-btn>
        <v-btn depressed class="mt-1" block @click="cancel"> 取消 </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "",
  props: [""],
  components: {},
  data() {
    return {
      loading: false,
      form: {
        domain: "",
        src_ip_addr_v4: "",
        dst_ip_addr_v6: "",
        ttl: "",
        id: 0,
      },
    };
  },
  methods: {
    getOne() {
      let id = sessionStorage.getItem("addressAllocId");
      if (id && id > 0) {
        this.$axios
          .get(`/trunkserver/assets/address-alloc/${id}/`)
          .then((res) => {
            if (res.data && res.data.id > 0) {
              this.form = res.data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    submit() {
      this.loading = true;
      if (this.form.id && this.form.id > 0) {
        //修改put
        this.$axios
          .put(`/trunkserver/assets/address-alloc/${this.form.id}/`, this.form)
          .then((res) => {
            if (res.data && res.data.id > 0) {
              this.$message.success("此操作已成功!");
              sessionStorage.setItem("addressAllocId", 0);
              this.$router.push({
                path: "/domain/address_alloc",
              });
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      } else {
        console.log(this.form);
        this.$axios
          .post("/trunkserver/assets/address-alloc/", this.form)
          .then((res) => {
            if (res.data && res.data.id > 0) {
              this.$message.success("此操作已成功!");
              sessionStorage.setItem("addressAllocId", 0);
              this.$router.push({
                path: "/domain/address_alloc",
              });
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }
    },
    cancel() {
      this.disabled = false;
      sessionStorage.setItem("addressAllocId", 0);
      this.$router.push({
        path: "/domain/address_alloc",
      });
    },
  },
  created() {
    this.getOne();
  },
};
</script>

<style lang="scss" scoped></style>
